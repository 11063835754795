import {userSession, searchState} from '../store/state';
import Title from '../ui/title';
import {useState} from 'react';
import {SearchBox} from '../ui/searchBox';
import EnhancedQueries  from '../ui/enhancedQueries';
import SearchResults from '../ui/searchResults';
import GetEnhancedQueries from '../async/llmapi';
import PerformSearch from '../async/search';
import CheckAccess from '../async/auth';
import {useQuery} from '@tanstack/react-query';

export default function SearchPage() {
    // zustand getters setters
    const accessKey = userSession((state:any) => state.accessKey);
    const query = searchState((state) => state.query); // this is to subscibe to query state changes
    const tmpQuery = searchState((state) => state.tmpQuery); // subscribing to tmpQuery state changes
    
    // react-query
    const searchQuery = useQuery({queryKey:['search', query, tmpQuery], queryFn: () => PerformSearch(query, tmpQuery, select)});
    const llmQuery = useQuery({queryKey:['enhanced', query], queryFn: () => GetEnhancedQueries(query, accessKey)});
    const authQuery = useQuery({queryKey:['auth', accessKey], queryFn: () => CheckAccess(accessKey)});

    // tracking the selected states
    let initialState = new Array(8).fill(0);
    initialState[0] = 1;
    const [select, setSelect] = useState(initialState);

    // select state callbacks
    const selectCallBack = (index:number) => {
        // updating the selected box
        const newSelect = select.map((e, i) => {
            if (i == index) {
                if (select[index] == 0) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        })
        // only changing selection when a search box has been clicked
        const allEqual = (arr : any[]) => arr.every(val => val === arr[0]);
        if (!allEqual(newSelect)) {
            setSelect(newSelect);
        }
    }

    // update for react-query compatibility
    return(
        <div>
            { authQuery.status === "success" && authQuery.data === true ? (
                <div>
                    <div className="flex flex-row h-full pt-6 justify-center">
                        <div></div>
                            <SearchBox select={select[0]} selectCallBack={selectCallBack}/>
                        <div></div>
                    </div>
                    <div className="flex flex-row p-10 pl-20">
                    {llmQuery.status === 'success' ? (
                        <EnhancedQueries queries={llmQuery.data} select={select} selectCallBack={selectCallBack}/>
                    ) : <div>Loading...</div>
                    } 
                    { searchQuery.status === 'success' ? (
                        <SearchResults results={searchQuery.data}/>
                    ) : <div>Loading...</div> }
                    </div>
                    <Title/>
                </div>
            ) : 
            <div className="absolute inset-0 top-1/2 text-center text-black">Sorry, you don't have access to this yet...</div>
            }
        </div>
    )
}