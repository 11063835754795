import { Route, Routes } from 'react-router-dom';
import LandingAuth from './routes/LandingAuth';
import SearchPage from './routes/SearchPage'
import './App.css';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';

// Create a react query client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 1000 * 60 * 60 * 24 // 24 hrs in milliseconds
    },
  },
});


function App() {
  // zustand
  // const u = searchState((state) => state.updateEnhancedQueries);
  // const uq = searchState((state) => state.updateQuery);

  // const us = searchState((state) => state.updateSearchResults);
  // u([
  //                       {
  //                         query: "The Origins of Elmo's World",
  //                         keywords: [
  //                           'PBS',
  //                           'Sesame Street',
  //                           'Elmo',
  //                           'educational programming',
  //                           "children's television"
  //                         ]
  //                       },
  //                       {
  //                         query: "The Evolution of Elmo's World",
  //                         keywords: [
  //                           'Sesame Street',
  //                           'Elmo',
  //                           'educational programming',
  //                           "children's television",
  //                           'television history'
  //                         ]
  //                       },
  //                       {
  //                         query: "The Impact of Elmo's World on Early Childhood Education",
  //                         keywords: [
  //                           'Sesame Street',
  //                           'Elmo',
  //                           'educational programming',
  //                           "children's television",
  //                           'early childhood education'
  //                         ]
  //                       },
  //                       {
  //                         query: "The Future of Elmo's World",
  //                         keywords: [
  //                           'Sesame Street',
  //                           'Elmo',
  //                           'educational programming',
  //                           "children's television",
  //                           'future of television'
  //                         ]
  //                       },
  //                       {
  //                         query: "The Legacy of Elmo's World",
  //                         keywords: [
  //                           'Sesame Street',
  //                           'Elmo',
  //                           'educational programming',
  //                           "children's television",
  //                           'legacy of television'
  //                         ]
  //                       }
  //                     ]);

  // us([
  //               {
  //                   title: 'Kevin Clash - Wikipedia',
  //                   href: 'https://en.wikipedia.org/wiki/Kevin_Clash',
  //                   body: `Kevin Jeffrey Clash (born September 17, 1960) is an American puppeteer, director and producer best known for puppeteering Elmo on Sesame Street from 1985 to 2012. He also performed puppets for Labyrinth, Dinosaurs, 
  //               Oobi, and various Muppet productions.. Clash developed an interest in puppetry at an early age and, in his teen years, performed for local television children&#x27;s shows in his ...`
  //               },
  //               {
  //                   title: 'History of Sesame Street - Wikipedia',
  //                   href: 'https://en.wikipedia.org/wiki/History_of_Sesame_Street',
  //                   body: 'Eventually, Elmo became, as Davis reported, "the embodiment" of Sesame Street, and "the marketing wonder of our age" when five million "Tickle Me Elmo" dolls were sold in 1996. Clash believed the "Tickle Me Elmo" phenomenon made Elmo a household name and led to the "Elmo&#x27;s World" segment.'
  //               },
  //               {
  //                   title: 'Kevin Clash: The Man Behind Sesame Street&#x27;s Elmo : NPR',
  //                   href: 'https://www.npr.org/2011/12/15/143582831/kevin-clash-the-man-behind-sesame-streets-elmo',
  //                   body: 'Clash&#x27;s story is told in the new documentary Being Elmo, which chronicles the puppeteer&#x27;s journey from performing in backyard shows for his mother&#x27;s home-based day care center, to working with Jim ...'
  //               },
  //               {
  //                   title: 'Elmo&#x27;s World - Wikipedia',
  //                   href: 'https://en.wikipedia.org/wiki/Elmo%27s_World',
  //                   body: 'Elmo&#x27;s World is a segment that is shown at the end of the American children&#x27;s television program Sesame Street which premiered on November 16, 1998, as part of a broader structural change to the show. It originally lasted fifteen minutes at the end of each episode. The segment ran until 2009, and then returned in 2017. The segment was designed to appeal to younger viewers and to increase ...'
  //               },
  //               {
  //                   title: 'In &#x27;Being Elmo,&#x27; Meet the Man Behind Sesame Street&#x27;s Red Monster',
  //                   href: 'https://www.theatlantic.com/entertainment/archive/2011/10/in-being-elmo-meet-the-man-behind-sesame-streets-red-monster/246984/',
  //                   body: 'A new documentary traces the history of Elmo and reveals the performer who animates one of the world&#x27;s most famous puppets. Constance Marks Productions, Inc. At a school for disabled children in ...'
  //               },
  //               {
  //                   title: '&#x27;Being Elmo&#x27;: The Man Behind the Muppet | PBS News',
  //                   href: 'https://www.pbs.org/newshour/arts/being-elmo',
  //                   body: 'A new documentary, "Being Elmo: A Puppeteer&#x27;s Journey," follows Clash&#x27;s life story, from when he dreamed of working with his idol, Jim Henson, to how he finds a home on Sesame Street. Art ...'
  //               },
  //               {
  //                   title: 'Kevin Clash: The Man Behind Sesame Street&#x27;s Elmo - TIME',
  //                   href: 'https://content.time.com/time/arts/article/0,8599,1937077,00.html',
  //                   body: 'Most of the time they don&#x27;t. But I&#x27;m telling you, they just see me holding their friend. They don&#x27;t know me from a hole in the wall and they don&#x27;t care to. You&#x27;re that guy hanging out with Elmo. I&#x27;m the guy holding their friend. Ever since I&#x27;ve been doing Sesame Street that&#x27;s been the case. They don&#x27;t care about us.'
  //               },
  //               {
  //                   title: 'The Man Behind Elmo - Newsweek',
  //                   href: 'https://www.newsweek.com/man-behind-elmo-109919',
  //                   body: 'In a piece that ran in mid-August called " Elmo Is an Evildoer ," Los Angeles Times editorialist Joel Stein took the character to task for ruining the "Sesame Street" he remembers as a child ...'
  //               },
  //               {
  //                   title: 'Elmo&#x27;s World | Muppet Wiki | Fandom',
  //                   href: 'https://muppet.fandom.com/wiki/Elmo%27s_World',
  //                   body: 'Elmo&#x27;s World is a segment on Sesame Street starring Elmo. It ran consistently from seasons 30 through 42 (with the exception of two episodes in season 38). Each segment, or episode, runs approximately 20 minutes long following the same, basic format. Regular features include inserts featuring the Noodle Family, Elmo asking questions of a baby and email messages which feature other Sesame ...'
  //               },
  //               {
  //                   title: 'Sesame Street | History, Characters, &amp; Facts | Britannica',
  //                   href: 'https://www.britannica.com/topic/Sesame-Street',
  //                   body: 'Sesame Street: Bert and Ernie. Bert and Ernie from Sesame Street. A pioneering children&#x27;s education series, Sesame Street features animations, live actors, and a core cast of puppet characters, the Muppets. Designed by Jim Henson, the Muppets—especially the impossibly cute Elmo, Big Bird, the inseparable Bert and Ernie, and Cookie Monster ...'
  //               }
  //               ]);
  return (
    <>
    <Routes>
       <Route path="/" element={<QueryClientProvider client={queryClient} ><LandingAuth/></QueryClientProvider>}/>
       <Route path="/search" element={<QueryClientProvider client={queryClient} ><SearchPage/></QueryClientProvider>}/>
    </Routes>
    </>
  );  
}

export default App;