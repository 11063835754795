export default async function GetEnhancedQueries(query:string, authorization:string) {
  if (query == "") {return []};
  const proxyUrl = "https://ai.sophist.uk/llm";
  const fetchOAI = await fetch(proxyUrl, {method: 'POST',
        headers: {
            'Authorization': authorization,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({query: query})}
  )
  return fetchOAI.text();
}