import {Fragment} from 'react';
import EnhancedBox from './enhancedBox'

export default function EnhancedQueries({queries, select, selectCallBack} : { queries : any, select: number[], selectCallBack:(index: number) => void}) {
    const enhancedSelect = select.slice(1);
    let queryArr = Object.create(null);
    if (queries.length > 0) {
        queryArr = JSON.parse(queries);
    }

    return (
    <div className="w-1/4">
        <div className="pl-2 text-slate-400">Suggested queries</div>
        <div className="flex flex-col h-[70vh] w-full pt-6 pl-2 overflow-y-scroll">
            
            {Object.keys(queryArr)?.map((key, i) => (
                <Fragment key={i}>
                    <div className="pt-1 pb-3">
                        <EnhancedBox query={key} keywords={queryArr[key]} index={i+1} select={enhancedSelect[i]} selectCallBack={selectCallBack}/>
                    </div>
                </Fragment>
            ))}
        </div>
    </div>
    )
} 