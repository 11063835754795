import {useRef, useState} from 'react';
import {searchState} from '../store/state';
import clsx from 'clsx';

export function SearchBox({select, selectCallBack} : {select : number, selectCallBack:(index: number) => void}){
    // collecting all the state hooks
    const ref = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    // zustand getters and setters
    const currentQuery = searchState((state:any) => state.query);
    const updateQuery = searchState((state:any) => state.updateQuery);
    const updateTmpQuery = searchState((state:any) => state.updateTmpQuery);

    // setting the default query of the text box
    const [queryState, setQueryState] = useState(currentQuery);

    // component methods
    const handleFocus = () => {
        selectCallBack(0);
        updateTmpQuery("");
    }

    const submitQuery = (e:React.FormEvent<HTMLFormElement>) => {
        if (select == 1) {
            e.preventDefault();
            updateQuery(ref.current?.value);
        }
    }

    const handleKeyDown = (e : any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (formRef.current !== null) {
                formRef.current.requestSubmit();
            }
        }
      };

    return(
        <div className={clsx("block rounded-sm w-1/3 pt-4",
        {
            'bg-slate-200' : select == 1,
        },
        )}>
            <div className="flex flex-col w-full border-x bg-gradient-to-t from-slate-100 to-bg-gray-50 pt-6 pl-10 pr-10 pb-1">
                <form ref={formRef} className="w-full " onSubmit={submitQuery}>
                    <textarea 
                        className="block float-right text-black w-full bg-inherit focus:outline-none placeholder:text-slate-500"
                        value={queryState?.toString()}
                        onChange={(e) => {
                            e.preventDefault();
                            setQueryState(e.target.value)
                        }}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        onBlur={handleFocus}
                        name="search"
                        ref={ref}
                        placeholder="What question do you want to ask, but don't have the words to?"
                    />
                </form>
                <div className="relative self-end text-black text-xl pb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                        <path fillRule="evenodd" d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z" clipRule="evenodd" />
                    </svg>
                </div>

            </div>
            <div className="relative w-full h-0.5 bg-slate-900 radius-full"></div>
        </div>
    );
}