import {load} from "cheerio";

// Function to query DuckDuckGo and scrape search results
export default async function PerformSearch(query:string, tmpQuery:string, select:number[]) {
    if (query === "" && tmpQuery === "") {return []}
    let searchQuery = "";
    if (select[0] == 1) {
        searchQuery = query;
    } else {
        searchQuery = tmpQuery
    }

    const url = `https://ai.sophist.uk/web?q=${encodeURIComponent(searchQuery)}`;
    let resultsArray: string[][] = [];  // Array to store the search results
    
    try {
        // Fetch the HTML from DuckDuckGo
        const response = await fetch(url);
        const data = await response.text(); 
        
        // Load HTML into cheerio
        const $ = load(data);
        
        // Select each result div with class 'result results_links results_links_deep web-result'
        $('.result.results_links.results_links_deep.web-result').each((index, element) => {
            // Extract the result text, trim whitespace, and remove newline characters
            let resultText = $(element).text().trim();
            resultText = resultText.replace(/\n/g, ''); // Remove all newline characters
            const resultTexts = resultText.split(/\s{2,}/); // split string by all whitespaces greater than 2 characters long
            
            // Push the cleaned result text into the array
            resultsArray.push(resultTexts);
        });
        
        return resultsArray; // Return the array if needed for further use
    } catch (error) {
        throw new Error('Error fetching search results:' + error);
    }
}
