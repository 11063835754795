import clsx from 'clsx';
import {useState} from 'react';

export default function SuggestedKey({name, index, keyIndex, keywordSelectCallBack, searchSelectCallBack} : {name: string, index:number, keyIndex:number,keywordSelectCallBack:(select:number, index: number) => void, searchSelectCallBack:(index: number) => void}) {
    const [isSelect, setSelect] = useState(0);
    return (
        <div className="">
            <button className={clsx(
                "hover:bg-[#d5e3ff] p-0.5",
                {
                    'bg-[#2F52E0] hover:bg-blue-800 border-[#2F52E0]' : isSelect == 1,
                },
            )} 
                    type="button" 
                    onClick={(e) => {
                        if (isSelect == 1) {
                            setSelect(0);
                            keywordSelectCallBack(0, keyIndex);
                        } else {
                            setSelect(1);
                            keywordSelectCallBack(1, keyIndex);
                        }
                    }}
                    onFocus={(e) => {
                        searchSelectCallBack(index);
                    }}
                    onBlur={(e) => {
                        searchSelectCallBack(index);
                    }}>

                <span className={clsx("text-sm text-blue-600 pr-3 pl-3 pb-6 whitespace-nowrap",
                    {
                        'text-white' : isSelect == 1,
                    }
                )}>{name}</span>
            </button>
        </div>
    )
}