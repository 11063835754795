import {useState, useRef, useEffect, Fragment} from 'react';
import {searchState} from '../store/state';
import clsx from 'clsx';
import SuggestedKey from './suggestedKey';

export default function EnhancedBox({query, keywords, index, select, selectCallBack} : {query :string, keywords:string[], index:number, select:number, selectCallBack:(index: number) => void}){
    const keyArr = Array.from(keywords);
    // tracking all the stateful components
    const ref = useRef<HTMLTextAreaElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    const [queryState, setQuery] = useState(query);

    // tracking state for all of the keywords
    const initialKeyState = new Array(keywords.length).fill(0);
    const [keyState, setKeywords] = useState(initialKeyState);

    // zustand 
    const updateTmpQuery = searchState((state) => state.updateTmpQuery);

    const keywordSelectCallBack = (select : number , index : number) => {
        // adding the keyword
        if (select == 1) {
            const newKeyState = keyState.map((e, i) => {
                if (i == index) {
                    return 1;
                } else {
                    return e;
                }
            });
            setKeywords(newKeyState);
        } else {
            const newKeyState = keyState.map((e, i) => {
                if (i == index) {
                    return 0;
                } else {
                    return e;
                }
            });
            setKeywords(newKeyState);
        }
    }

    // sending a new query when user selects a new keyword
    useEffect(() => {
        formRef.current?.requestSubmit();
    }, [keyState]);

    // changing the state of the searchboxes
    const updateEnhancedSearchResults = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        // pulling the temp query and triggering a new search
        let tmpquery = ref.current?.value;
        if (tmpquery !== "" && tmpquery !== null && tmpquery !== undefined && select == 1) {
            // adding keywords
            for(const k in keywords){
                if (keyState[k] == 1){
                    tmpquery = tmpquery +` ~"${keywords[k]}"`;
                }
            }
            // updating the tmpquery state
            updateTmpQuery(tmpquery);
        }
    }

    const handleFocus = () => {
        // clearing the current tmpQuery
        selectCallBack(index);
        updateTmpQuery("");
    }

    const handleKeyDown = (e:any) => {
        e.preventDefault();
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            formRef.current?.requestSubmit();
        }
      };

    useEffect(() => {
        setQuery(query);
    }, [query]);

    useEffect(() => {
        if (select == 1) {
            formRef.current?.requestSubmit();
        }

        if (ref.current) {
            ref.current.style.height = 'auto';
            ref.current.style.height = `${ref.current.scrollHeight}px`;
        }
    }, [select])

    return(
        <div className={clsx("block rounded-sm w-full pt-2",
            {
                'bg-blue-100' : select == 1,
            },
            )}>
            <div className="flex flex-col border-x bg-gradient-to-t from-blue-100 to-bg-gray-50">
                <form ref={formRef} className="w-full" onSubmit={updateEnhancedSearchResults}>
                    <textarea 
                        className="block w-full pl-6 pr-6 pt-4 pb-2 text-blue-600 bg-inherit focus:outline-none"
                        placeholder="Describe your problem"
                        value={queryState}
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                        onFocus={handleFocus}
                        name="search"
                        ref={ref}
                    />
            </form>
            </div>
            <div className="relative h-0.5 bg-blue-800 radius-full"></div>
            <div className="flex flex-row flex-wrap">
                {keyArr?.map((e, i) => (
                    <Fragment key={i}>
                        <SuggestedKey name={e} index={index} keyIndex={i} keywordSelectCallBack={keywordSelectCallBack} searchSelectCallBack={selectCallBack}/>
                    </Fragment>
                ))}
            </div>
        </div>
    );
}