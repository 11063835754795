import {create} from 'zustand'
import {persist} from 'zustand/middleware'

export const searchState = create(
    persist(
        (set:any) => ({
            query: "",
            tmpQuery: "",
            updateQuery: (newQuery:string) => set({query: newQuery}),
            updateTmpQuery: (tmpQuery:string) => set({tmpQuery: tmpQuery}),
        }),
        { name: "searchState"}
    )
)

export const userSession = create(
    persist(
        (set:any) => ({
            accessKey: "",
            addKey: (accessKey:string) => set(() => ({accessKey : accessKey})),
            }),
        {
            name:'userSesh'
        })
    );