import {useState, useRef} from 'react'
import {userSession} from '../store/state'
import {Navigate} from 'react-router-dom'
import {useQuery} from '@tanstack/react-query'
import CheckAccess from '../async/auth'

export default function LandingAuth() {
    const [accessKey, setKey] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    const formRef = useRef<HTMLFormElement>(null);

    // zustand getters
    const setSeshKey = userSession((state:any) => state.addKey);
    const getKey = userSession((state:any) => state.accessKey);

    // react-query
    const authQuery = useQuery({queryKey:['auth', getKey], queryFn: () => CheckAccess(getKey)});

    // component methods
    const submitForm = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // user setting the new access key
        const aKey = inputRef.current?.value;
        setSeshKey(aKey);
    }

    const handleKeyDown = (e : any) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (formRef.current !== null) {
                formRef.current.requestSubmit();
            }
        }
    }

    // zustand validation logic on rerender
    if (authQuery.status === 'success' && authQuery.data === true) {
        return <Navigate to="/search" />;
    } else {
        return(
            <main className="absolute m-auto w-1/3 inset-0 top-1/2 min-h-screen">
                <div className="relative inset-0 items-center text-center w-full">
                    <div className="text-black text-center">Welcome to the Sophist pre-alpha release</div>
                    <a className="text-blue-700" href="https://forms.gle/MZqmFXFDJk9k39F47">Click here for access</a>
                    <form ref={formRef} onSubmit={submitForm}>
                        <input className="w-full pt-6 text-black focus:outline-none"
                            placeholder="Enter your access key here"
                            value={accessKey}
                            onChange={(e) => setKey(e.target.value)}
                            onKeyDown={handleKeyDown}
                            name="accessKey"
                            ref={inputRef}
                        />
                        <div className="relative w-full h-0.5 bg-slate-900 radius-full"></div>
                    </form>
                { authQuery.data === 'false' ? <div className="text-grey-600">Please enter a valid key</div> : <></>}
                </div>
            </main>
        )
    }
}