import { Fragment } from 'react';

export default function SearchResults({results} : {results : any[]}) {
    return(
        <div className="flex-col text-black h-[73vh] pl-20 mr-10 overflow-y-scroll w-3/5">
        {results?.map((e, index) => (
            <Fragment key={index}>
                <div className="pb-5">
                    <div className="text-black font-bold">{e[0]}</div>
                    <a style={{display: "table-cell"}} 
                    href={`https://${e[1]}`}
                    className="text-blue-700"
                    target="_blank"
                    rel="noopener noreferrer">{e[1]}</a>
                    <div className="text-black font-family-serif" >{e[2]}</div>
                </div>
            </Fragment>
        ))}
        </div>
    )
}